import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { SetConversationAsResolved } from 'src/app/conversation/state/actions';
import { getConversation, getConversationClient, getConversationPatient } from 'src/app/conversation/state/selectors';
import { PracticeFeature } from 'src/app/enums/practice-feature';
import { practiceHasFeature } from 'src/app/helpers/practice-has-feature';
import { PaymentRequest } from 'src/app/interfaces/payment-request';
import { Client } from 'src/app/models/Client';
import { Conversation } from 'src/app/models/Conversation';
import { AppState } from 'src/app/state/reducers';

@Component({
  selector: 'manage-conversation-action-bar',
  templateUrl: './manage-conversation-action-bar.component.html',
  styleUrls: ['./manage-conversation-action-bar.component.scss']
})
export class ManageConversationActionBarComponent implements OnInit, OnDestroy {
  @ViewChild('modalBg') modalBg?: ElementRef;
  @Input() headerElement?: HTMLDivElement;
  alive = true;
  conversation: Conversation | null = null;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscribeToConversation();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToConversation(): void {
    this.store.pipe(select(getConversation))
      .pipe(takeWhile(() => this.alive))
      .subscribe(conversation => {
        this.conversation = conversation;
      });
  }

  resolveConversation(settings: { sendToPms: boolean, downloadPdf: boolean, sendTextToPms: boolean }): void {
    if (this.conversation) {
      this.store.dispatch(SetConversationAsResolved({conversationId: this.conversation.id, saveToPms: settings.sendToPms, downloadPdf: settings.downloadPdf, sendTextToPms: settings.sendTextToPms}));
    }
  }
}
