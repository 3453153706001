import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subscription, takeWhile } from "rxjs";
import { getMessagesReversed } from "../../../messages/state/selectors";
import { Conversation } from "../../../models/Conversation";
import {
  SetConversationPreviewOpen,
  SetPreviewConversationId,
} from "../../../state/actions";
import { AppState } from "../../../state/reducers";
import {
  GetClient,
  SetConversationAsResolved,
  SetTagsOnConversation,
} from "../../state/actions";
import { Message } from "../../../models/Message";
import { getConversation } from "../../state/selectors";
import { Channel } from "../../../enums/channel";
import { getTags } from "../../../state/selectors";
import { getCurrentPractice } from "../../../practices/state/selectors";
import { Tag } from "../../../models/Tag";
import { Practice } from "../../../models/Practice";
import { Role } from "../../../enums/role";
import { Contact } from "../../../models/Contact";
import { WebsocketService } from "../../websocket.service";
import { UserAdapter } from "../../../adapters/user.adapter";
import { CookieService } from "ngx-cookie-service";
import { User } from "../../../models/User";
import { getUser } from "../../../auth/state/selectors";
import { GetPracticeStaff } from "../../../practices/state/actions";
import { practiceHasFeature } from "../../../helpers/practice-has-feature";
import { PracticeFeature } from "../../../enums/practice-feature";
import {
  ClearViewers,
  GetViewers,
  GetViewersSuccess,
} from "../../../viewers/state/actions";
import { getViewersExludingSelf } from "../../../viewers/state/selectors";
import { ViewerType } from "../../../enums/viewers-type";

@Component({
  selector: "conversation-preview",
  templateUrl: "./conversation-preview.component.html",
  styleUrls: ["./conversation-preview.component.scss"],
})
export class ConversationPreviewComponent implements OnInit, OnDestroy {
  alive = true;
  conversation: Conversation | null = null;
  messages$?: Observable<Message[] | null>;
  messages: Message[] | null = [];
  chatIsDisabled = true;
  chatIsAwaitingResponse = false;
  allTags: Tag[] = [];
  selectedTags: Tag[] = [];
  showManageTags = false;
  practice: Practice | null = null;
  contact?: Contact;
  superAdmin = Role.SUPER_ADMIN;
  analyst = Role.ANALYST;
  users: User[] = [];
  topBarMessage: string = "";
  showUsers: boolean = false;
  impersonator: string = "";
  authUser$?: Observable<User | null>;
  authUser: User | null = null;
  viewers$: Observable<User[] | null> = new BehaviorSubject(null);
  viewers: User[] = [];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private websocketService: WebsocketService,
    private userAdapter: UserAdapter,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    this.subscribeToConversation();
    this.subscribeToConversationMessages();
    this.subscribeToAuthUser();
    this.subscribeToPractice();
    this.checkForImpersonator();
    this.subscribeToTags();
    this.subscribeToWebsockets();
    this.subscribeToViewers();

    this.websocketService
      .getViewers()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        const viewers = data.map((viewer) => this.userAdapter.run(viewer));
        this.store.dispatch(GetViewersSuccess({ viewers: viewers }));
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.conversation && this.conversation.id && this.authUser) {
      this.websocketService.leaveConversation(
        this.conversation.id,
        this.authUser.id,
      );
      this.store.dispatch(ClearViewers());
    }
  }

  subscribeToViewers(): void {
    this.viewers$ = this.store
      .pipe(select(getViewersExludingSelf))
      .pipe(takeWhile(() => this.alive));

    this.viewers$.subscribe((viewers) => {
      if (viewers) {
        this.viewers = viewers;
      }
    });
  }

  subscribeToConversation(): void {
    this.store
      .pipe(select(getConversation))
      .pipe(takeWhile(() => this.alive))
      .subscribe((conversation: Conversation | null) => {
        this.store.dispatch(ClearViewers());
        this.conversation = conversation;
        this.updateChatIsDisabled();
        this.selectedTags = this.conversation?.tags ?? [];
        this.getViewers();

        if (conversation) {
          if (conversation.client) {
            this.store.dispatch(
              GetClient({ clientId: conversation.client.id }),
            );
          }

          const externalUser = conversation.users
            ? conversation.users.find(
                (user) => user.channel !== Channel.INTERNAL,
              )
            : null;
          if (externalUser && externalUser.phone) {
            this.contact = {
              facebookLinked: false,
              instagramLinked: false,
              name: externalUser.fullName,
              value: externalUser.phone,
              typeCode: "0",
            };
          }
        }
      });
  }

  getViewers(): void {
    if (this.conversation) {
      this.store.dispatch(
        GetViewers({
          id: this.conversation.id,
          modelType: ViewerType.CONVERSATION,
        }),
      );
    }
  }

  subscribeToWebsockets(): void {
    this.websocketService.onConnect$.subscribe((connected) => {
      if (
        connected &&
        this.conversation &&
        this.conversation.id &&
        this.authUser
      ) {
        let sendUser = true;
        if (this.impersonator || this.practice?.userRole === Role.SUPER_ADMIN) {
          sendUser = false;
        }
        this.websocketService.joinConversation(
          this.conversation.id,
          this.authUser.id,
          sendUser,
        );
      }
    });
  }

  checkForImpersonator(): void {
    const impersonator = this.cookieService.get("impersonate");
    if (impersonator) {
      this.impersonator = impersonator;
    }
  }

  subscribeToAuthUser(): void {
    this.authUser$ = this.store
      .pipe(select(getUser))
      .pipe(takeWhile(() => this.alive));

    this.authUser$.subscribe((user) => {
      if (user) {
        this.authUser = user;
        this.store.dispatch(GetPracticeStaff());
      }
    });
  }

  subscribeToTags(): void {
    this.store
      .pipe(select(getTags))
      .pipe(takeWhile(() => this.alive))
      .subscribe((tags) => {
        this.allTags = tags;
      });
  }

  subscribeToPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        this.practice = practice;
        this.showUsers = practiceHasFeature(
          practice,
          PracticeFeature.SHOW_TEAM_LOCATION,
        );
      });
  }

  subscribeToConversationMessages(): void {
    this.messages$ = this.store
      .pipe(select(getMessagesReversed))
      .pipe(takeWhile(() => this.alive));

    this.messages$.subscribe((messages) => (this.messages = messages));
  }

  goToConversation(): void {
    if (this.conversation) {
      this.store.dispatch(SetConversationPreviewOpen({ open: false }));
      this.store.dispatch(SetPreviewConversationId({ id: null }));
      this.router.navigateByUrl("/conversations/" + this.conversation.id);
    }
  }

  resolveConversation(settings: {
    sendToPms: boolean;
    downloadPdf: boolean;
    sendTextToPms: boolean;
  }): void {
    if (this.conversation) {
      this.store.dispatch(
        SetConversationAsResolved({
          conversationId: this.conversation.id,
          saveToPms: settings.sendToPms,
          downloadPdf: settings.downloadPdf,
          sendTextToPms: settings.sendTextToPms,
        }),
      );
    }
  }

  updateChatIsDisabled(): void {
    this.chatIsDisabled =
      !!this.conversation?.resolvedAt || this.conversation?.users?.length === 0;
  }

  updateChatIsAwaitingResponse(): void {
    this.chatIsAwaitingResponse = !!(
      this.conversation &&
      (this.conversation.channel === Channel.WHATSAPP ||
        this.conversation.channel === Channel.WHATSAPP360 ||
        this.conversation.channel === Channel.WHATSAPP360CLOUD) &&
      (!this.conversation.timeSinceLastClientResponse ||
        this.conversation.timeSinceLastClientResponse >= 86400)
    );
  }

  closePreview(): void {
    this.store.dispatch(SetConversationPreviewOpen({ open: false }));
    this.store.dispatch(SetPreviewConversationId({ id: null }));
  }

  setTags(tags: Tag[]): void {
    if (this.conversation) {
      this.store.dispatch(
        SetTagsOnConversation({ tags, conversation: this.conversation }),
      );
    }
  }

  handleTagsChanged(tags: Tag[]): void {
    this.selectedTags = tags;
    this.setTags(this.selectedTags);
  }
}
