import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Practice } from "../../../models/Practice";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../state/reducers";
import { takeWhile } from "rxjs/operators";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { Client } from "src/app/models/Client";
import {
  getPaymentRequestLoading,
  getPaymentRequestClient,
  getSubmitPaymentRequestFailed,
} from "../../../payments/state/selectors";
import { PaymentRequest } from "../../../interfaces/payment-request";
import { Contact } from "../../../models/Contact";
import { SetPaymentClient, SubmitPaymentConversationRequest, UpdatePaymentRequestLoading } from "../../../payments/state/actions";
import { Channel } from "../../../enums/channel";
import { getCurrentPractice } from "../../../practices/state/selectors";
import { getOpenConversations } from "../../../waiting-room/state/selectors";
import { Conversation } from "../../../models/Conversation";
import { practiceHasFeature } from "../../../helpers/practice-has-feature";
import { PracticeFeature } from "../../../enums/practice-feature";
import phone from "phone";
import { phoneValidator } from "../../../helpers/phone-validator";

interface ChannelOption {
  name: Channel;
  code: Channel;
  className: string;
  icon: string;
  inactive: boolean;
}

@Component({
  selector: "new-payment",
  templateUrl: "./new-payment.component.html",
  styleUrls: ["./new-payment.component.scss"],
})
export class NewPaymentComponent implements OnInit, OnDestroy {
  @Input() practice?: Practice;
  alive = true;
  step = 0;
  selectedClient?: Client;
  selectedContact?: Contact;
  newPaymentFailed$?: Observable<boolean>;
  paymentRequestLoading$?: Observable<boolean>;
  channels: ChannelOption[] = [
    {
      name: Channel.WHATSAPP,
      code: Channel.WHATSAPP,
      className: Channel.WHATSAPP.toLowerCase(),
      icon: "WhatsApp.svg",
      inactive: false,
    },
    {
      name: Channel.SMS,
      code: Channel.SMS,
      className: Channel.SMS.toLowerCase(),
      icon: "SMS.svg",
      inactive: false,
    },
  ];
  selectedChannel: ChannelOption = this.channels[0];
  facebookLinked = false;
  instagramLinked = false;
  channel = Channel;
  conversations$?: Observable<Conversation[]>;
  conversations: Conversation[] = [];
  activeConversation = false;
  balanceEnabled = false;
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscribeToNewPayment();
    this.subscribeToPractice();
    this.subscribeToConversations();
   this.subscribeToProductRequestClient();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.store.dispatch(SetPaymentClient({}));
    this.reset();
  }

  subscribeToConversations(): void {
    this.conversations$ = this.store
      .select(getOpenConversations)
      .pipe(takeWhile(() => this.alive));

    this.conversations$.subscribe((conversations) => {
      if (conversations) {
        this.conversations = conversations;
      }
    });
  }

  selectClient(client: Client): void {
    this.selectedClient = client;

    if (this.conversations.find((c) => c.client?.pmsId == client.pmsId)) {
      this.activeConversation = true;
    } else {
      this.activeConversation = false;
    }

    if (this.activeConversation) {
      this.step = 3;
    } else {
      this.step++;
    }
  }

  selectContact(contact: Contact): void {
    this.selectedContact = contact;

    if (contact.facebookLinked) {
      this.facebookLinked = true;
    }

    if (contact.instagramLinked) {
      this.instagramLinked = true;
    }

    const phoneNumber = contact.e164 ? contact.e164 : contact.value;

    let result;
    if (this.practice) {
      result = phoneValidator(phoneNumber, this.practice?.country);
    }

    if (result && phone(result).isValid) {
      this.step++;
    } else {
      this.step = 5;
    }
  }

  subscribeToNewPayment(): void {
    this.paymentRequestLoading$ = this.store
      .pipe(select(getPaymentRequestLoading))
      .pipe(takeWhile(() => this.alive));
    this.newPaymentFailed$ = this.store
      .pipe(select(getSubmitPaymentRequestFailed))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        if (practice) {
          let channelCode = practice.whatsapp_channel;

          this.channels = [
            {
              name: Channel.WHATSAPP,
              code: channelCode,
              className: Channel.WHATSAPP.toLowerCase(),
              icon: "WhatsApp.svg",
              inactive: false,
            },
            ...this.channels.filter(
              (channelOpt) =>
                channelOpt.code !== Channel.WHATSAPP &&
                channelOpt.code !== Channel.WHATSAPP360 &&
                channelOpt.code !== Channel.WHATSAPP360CLOUD,
            ),
          ];
          this.selectedChannel = this.channels[0];
          this.balanceEnabled = practiceHasFeature(this.practice, PracticeFeature.CLIENT_BALANCE);
          if (!practiceHasFeature(practice, PracticeFeature.SMS_MESSAGING)) {
            this.channels = this.channels.filter(
              (channel) => channel.code !== Channel.SMS,
            );
          }

          if (
            practiceHasFeature(practice, PracticeFeature.FACEBOOK_MESSENGER)
          ) {
            this.channels.push({
              name: Channel.FACEBOOK,
              code: Channel.FACEBOOK,
              className: Channel.FACEBOOK.toLowerCase(),
              icon: "facebook-messenger.svg",
              inactive: false,
            });
          } else {
            this.channels = this.channels.filter(
              (channel) => channel.code !== Channel.FACEBOOK,
            );
          }

          if (practiceHasFeature(practice, PracticeFeature.INSTAGRAM)) {
            this.channels.push({
              name: Channel.INSTAGRAM,
              code: Channel.INSTAGRAM,
              className: Channel.INSTAGRAM.toLowerCase(),
              icon: "instagram.svg",
              inactive: false,
            });
          } else {
            this.channels = this.channels.filter(
              (channel) => channel.code !== Channel.INSTAGRAM,
            );
          }
        }
      });
  }

  paymentRequested(paymentRequest: PaymentRequest): void {
    if (this.selectedClient) {
      this.store.dispatch(
        SubmitPaymentConversationRequest({
          request: paymentRequest,
          client: this.selectedClient,
          contact: this.selectedContact ?? null,
          channel: this.selectedChannel.code,
        }),
      );
      this.store.dispatch(UpdatePaymentRequestLoading({isLoading : true}));
    }
  }

  reset(): void {
    this.step = 0;
    this.selectedClient = undefined;
    this.selectedContact = undefined;
    this.activeConversation = false;
  }

  onChannelChange(channel: ChannelOption): void {
    this.selectedChannel = channel;
  }
  subscribeToProductRequestClient(): void {
    this.store
      .pipe(select(getPaymentRequestClient))
      .pipe(takeWhile(() => this.alive))
      .subscribe((client) => {
        if(client.client){
          this.selectClient(client?.client)
        }
      });
  }

  handleSelectedChannel(): void {
    if (
      (this.selectedChannel.code !== Channel.FACEBOOK &&
        this.selectedChannel.code !== Channel.INSTAGRAM) ||
      this.facebookLinked ||
      this.instagramLinked
    ) {
      this.step++;
    }
  }

  handleBackFromPaymentForm(): void {
    if (this.activeConversation) {
      this.step = 0;
    } else {
      this.step = this.step - 1;
    }
  }
}
