import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Client } from 'src/app/models/Client';
import { select, Store } from '@ngrx/store';
import {getCurrentPractice, getCurrentPracticeSites} from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs/operators';
import { AppState } from '../../../state/reducers';
import { Observable } from 'rxjs';
import { Practice } from '../../../models/Practice';
import { getCurrencies } from '../../../state/selectors';
import { Currency } from '../../../models/Currency';
import { practiceHasFeature } from 'src/app/helpers/practice-has-feature';
import { PracticeFeature } from 'src/app/enums/practice-feature';
import { Router } from '@angular/router';

@Component({
  selector: 'client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit, OnDestroy {
  @Input() client?: Client;
  @Input() disabled?: boolean;
  @Input() changeDisabled = false;
  @Output() clientChanged = new EventEmitter();
  currencies: Currency[] = [];
  practiceCurrency?: Currency;
  alive = true;
  practice$?: Observable<Practice | null>;
  practice: Practice | null = null;
  lastTxAmountEnabled = false;
  lastTxDateEnabled = false;
  balanceEnabled = false;
  depositHeldEnabled = false;
  clientSite?: string | null;
  accountTypeEnabled = false;
  clientInsight = false;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToCurrencies();
    this.subscribeToPracticeSites();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  changeClient(): void {
    this.clientChanged.emit();
  }

  handleClientClick(clientId: string): void {
    if (this.clientInsight) {
      this.redirectToClient(clientId);
    } else {
    }
  }

  redirectToClient(clientId: string): void {
    this.router.navigateByUrl(`/clients/${clientId}`);
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(practice => {
      this.practice = practice;
      this.setPracticeCurrency();
      this.updateFeatures();
      this.setPracticeCurrency();
    });
  }

  subscribeToPracticeSites(): void {
    this.store.select(getCurrentPracticeSites).pipe(takeWhile(() => this.alive)).subscribe(sites => {
      if (sites.length > 1) {
        this.clientSite = sites.find(site => site.vetbookerResourceId === this.client?.siteId?.toString())?.name;
      }
    });
  }

  subscribeToCurrencies(): void {
    this.store.pipe(select(getCurrencies)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(currencies => {
      this.currencies = currencies;
      this.setPracticeCurrency();
    });
  }

  setPracticeCurrency(): void {
    if (this.currencies.length && this.practice) {
      this.practiceCurrency = this.currencies.find((currency) => currency.currencyCode === this.practice?.currency);
    }
  }

  updateFeatures(): void {
    this.lastTxAmountEnabled = practiceHasFeature(this.practice, PracticeFeature.LAST_TRANSACTION);
    this.lastTxDateEnabled = practiceHasFeature(this.practice, PracticeFeature.LAST_TRANSACTION_DATE);
    this.balanceEnabled = practiceHasFeature(this.practice, PracticeFeature.CLIENT_BALANCE);
    this.accountTypeEnabled = practiceHasFeature(this.practice, PracticeFeature.CLIENT_ACCOUNT_TYPE);
    this.depositHeldEnabled = practiceHasFeature(this.practice, PracticeFeature.DISPLAY_CLIENT_DEPOSIT);
    this.clientInsight = practiceHasFeature(this.practice, PracticeFeature.CLIENT_INSIGHTS_PAGE);
  }
}
