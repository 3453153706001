import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemDialogsComponent } from './components/system-dialogs/system-dialogs.component';
import { UiModule } from '../ui/ui.module';
import {ProductRequestsModule} from '../product-requests/product-requests.module';
import {FormsModule} from '../forms/forms.module';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  declarations: [
    SystemDialogsComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    UtilsModule,
    ProductRequestsModule,
    FormsModule,
  ],
  exports: [
    SystemDialogsComponent
  ]
})
export class DialogsModule { }
