import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'src/app/models/Client';
import { ClientService } from '../../../clients/client.service';
import { AppState } from '../../../state/reducers';
import { Store, select } from '@ngrx/store';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { map, takeWhile, tap } from 'rxjs';
import { Practice } from '../../../models/Practice';
import { Patient } from '../../../models/Patient';
@Component({
  selector: 'client-list-item',
  templateUrl: './client-list-item.component.html',
  styleUrls: ['./client-list-item.component.scss']
})
export class ClientListItemComponent implements OnInit {
  @Input() client?: Client;
  @Output() clientSelected = new EventEmitter<Client>();
  alive: boolean = true;
  practice: Practice | null = null;
  loading: boolean = false;

  constructor(private clientService: ClientService, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        this.practice = practice;
      });
  }

  selectClient(): void {
    if (this.client && this.practice?.id) {
      this.loading = true;
      this.clientService.getClientByPmsId(this.client?.pmsId, this.practice?.id).pipe(
        map((result: { client: Client; patients: Patient[] }) => result.client),
        tap((client) => {
          
          this.client = client ;
        })
      ).subscribe(() => {
        this.loading = false;
        this.clientSelected.emit(this.client);
      });
    }
  }

}
