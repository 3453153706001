import { Injectable } from '@angular/core';

import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as MessagingActions from './actions';
import * as ConversationActions from '../../conversation/state/actions';
import { Message } from '../../models/Message';
import { MessagesService } from '../messages.service';
import { VideoService } from 'src/app/video/video.service';
import { Noop } from 'src/app/state/actions';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { MessageStatus } from '../../enums/message-status';

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private messagesService: MessagesService,
    private videoService: VideoService,
    private store: Store<AppState>,
  ) {
  }

  getMessages$ = createEffect(() => this.actions$.pipe(
    ofType(MessagingActions.GetMessages),
    mergeMap((action) => this.messagesService.getMessagesForConversation(action.conversationId)
      .pipe(
        map((result: Message[]) => {
          return MessagingActions.GetMessagesSuccess({messages: result});
        })
      ))
    )
  );

  addMessage$ = createEffect(() => this.actions$.pipe(
    ofType(MessagingActions.AddMessage),
    filter((action) => action.message.outbound === true && action.message.status !== MessageStatus.queued),
    map(() => {
        return ConversationActions.SetLastResponseAtToNow();
    })
  ));

  sendFile$ = createEffect(() => this.actions$.pipe(
    ofType(MessagingActions.SendFile),
    mergeMap((action) => this.messagesService.sendFileMessage(action.conversationId, action.file, action.caption)
      .pipe(
        map(() => {
          return MessagingActions.StopSendingFile();
        })
      ))
    )
  );

  sendMedia$ = createEffect(() => this.actions$.pipe(
      ofType(MessagingActions.SendMedia),
      mergeMap((action) => this.messagesService.sendMediaMessage(action.conversationId, action.media, action.caption)
        .pipe(
          map(() => {
            return MessagingActions.StopSendingFile();
          })
        ))
    )
  );

  sendAudio$ = createEffect(() => this.actions$.pipe(
    ofType(MessagingActions.SendAudio),
    mergeMap((action) => this.messagesService.sendFileMessage(action.conversationId, action.file)
      .pipe(
        map(() => {
          return MessagingActions.StopSendingAudio();
        })
      ))
    )
  );

  sendVideo$ = createEffect(() => this.actions$.pipe(
    ofType(MessagingActions.SendVideo),
    mergeMap((action) => this.videoService.createVideoCompressionJob(Number(action.conversationId), action.file.name, action.caption)
      .pipe(
        map((response: {id: string, url: string}) => {
          return MessagingActions.UploadVideo({
            id: response.id,
            presignedUrl: response.url,
            conversationId: action.conversationId,
            file: action.file
          });
        })
      ))
    )
  );

  uploadVideo$ = createEffect(() => this.actions$.pipe(
    ofType(MessagingActions.UploadVideo),
    mergeMap((action) => this.videoService.uploadVideo(action.presignedUrl, action.file)
      .pipe(
        map((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            let percentage = 0;
            if (event.total) {
              percentage = Math.round((event.loaded / event.total) * 100);
            }

            this.store.dispatch(MessagingActions.UpdateVideoUploadProgress({percentage}));
          }
          if (event.type === HttpEventType.Response) {
            this.store.dispatch(MessagingActions.VideoUploaded({
              id: action.id
            }));
          }
        })
      ))
    ), {dispatch: false}
  );

  videoUploaded$ = createEffect(() => this.actions$.pipe(
    ofType(MessagingActions.VideoUploaded),
    mergeMap((action) => this.videoService.notifyVideoUploadComplete(action.id)
      .pipe(
        map(() => {
          return Noop();
        })
      ))
    ), {dispatch: false}
  );

  deleteMessage$ = createEffect(() => this.actions$.pipe(
    ofType(MessagingActions.DeleteMessage),
    mergeMap((action) => this.messagesService.deleteMessage(action.messageId)
      .pipe(
        map((deleted: boolean) => {
          if (deleted) {
            return MessagingActions.DeleteMessageSuccess({messageId: action.messageId});
          }

          return Noop();
        })
      ))
    )
  );
}
