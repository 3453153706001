import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATE_KEY } from 'src/app/state/reducers';
import {PaymentsState} from './reducer';

export const getPaymentsState = createFeatureSelector<PaymentsState>(STATE_KEY.PAYMENTS);

export const isPaymentRequestOpen = createSelector(
  getPaymentsState,
  (state: PaymentsState) => state.paymentRequestOpen,
);

export const isNewPaymentRequestOpen = createSelector(
  getPaymentsState,
  (state: PaymentsState) => state.newPaymentRequestOpen,
);

export const getPaymentRequestLoading = createSelector(
  getPaymentsState,
  (state: PaymentsState) => state.paymentRequestLoading,
);

export const getPaymentSuccess = createSelector(
  getPaymentsState,
  (state: PaymentsState) => state.paymentSuccess,
);

export const getSubmitPaymentRequestFailed = createSelector(
  getPaymentsState,
  (state: PaymentsState) => state.paymentRequestFailed,
);

export const getPayments = createSelector(
  getPaymentsState,
  (state: PaymentsState) => state.payments,
);


export const getTotalPaymentCount = createSelector(
  getPaymentsState,
  (state: PaymentsState) => state.totalPaymentCount,
);

export const getSyncingPayments = createSelector(
  getPaymentsState,
  (state: PaymentsState) => state.syncingPayments,
);

export const getPaymentRequestClient = createSelector(
  getPaymentsState,
  (state: PaymentsState) => {
      return {
          client: state.client,
  }
}
)
