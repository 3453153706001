import { createAction, props } from '@ngrx/store';
import { PaymentRequest } from 'src/app/interfaces/payment-request';
import { Payment } from '../../models/Payment';
import { PaymentFilterDto } from '../../interfaces/dto/payment-filter.dto';
import { Channel } from '../../enums/channel';
import {Contact} from "../../models/Contact";
import {Client} from "../../models/Client";
import { CashUpFilters } from '../../interfaces/cash-up-filters.interface';

export const OpenPaymentRequest = createAction(
  '[Payments] Open Payment Request'
);

export const ClosePaymentRequest = createAction(
  '[Payments] Close Payment Request'
);

export const OpenNewPaymentRequest = createAction(
  '[Payments] Open New Payment Request'
);

export const CloseNewPaymentRequest = createAction(
  '[Payments] Close New Payment Request'
);

export const SubmitPaymentConversationRequest = createAction(
  '[Payments] Start New Payment/Conversation Request',
  props<{
    request: PaymentRequest,
    client: Client,
    contact: Contact | null,
    channel: Channel
  }>()
);
export const openPaymentRequestForClient = createAction(
  '[Payments] Open Payment Request For Client',
  props<{
    client?: Client,
  }>()
);
export const closePaymentRequestForClient = createAction(
  '[Payments] close Payment Request For Client',
  props<{
    client?: Client,
  }>()
);
export const SetPaymentClient = createAction(
  '[Forms] Set Payment Request Client',
  props<{client?: Client}>()
);

export const SubmitPaymentRequest = createAction(
  '[Payments] Submit Payment Request',
  props<{
    request: PaymentRequest,
    siteId: number,
    conversationId: string,
    clientId: string,
    patientId?: string,
  }>()
);

export const SubmitPaymentRequestSuccess = createAction(
  '[Payments] Submit Payment Request Success',
  props<{payment: Payment}>()
);

export const SubmitPaymentRequestFailed = createAction(
  '[Payments] Submit Payment Request Failed',
);

export const UpdatePaymentFilters = createAction(
  '[Payments] Update payment filters',
  props<{filters: PaymentFilterDto}>()
);

export const GetPayments = createAction(
  '[Payments] Get payments',
  props<{filters: PaymentFilterDto}>()
);

export const GetPaymentsSuccess = createAction(
  '[Payments] Get payments success',
  props<{payments: Payment[], total: number}>()
);

export const ClearPayments = createAction(
  '[Payments] Clear payments'
);

export const ResendPaymentLink = createAction(
  '[Payments] Resend payment',
  props<{payment: Payment, channel?: Channel}>()
);

export const CancelPaymentLinks = createAction(
  '[Payments] Cancel payments',
  props<{payments: Payment[]}>()
);

export const RetrySavePaymentToPms = createAction(
  '[Payments] Retry save payment to pms',
  props<{payment: Payment}>()
);

export const RetrySavePaymentToPmsSuccess = createAction(
  '[Payments] Retry save payment to pms success',
  props<{payment: Payment}>()
);

export const RetrySavePaymentToPmsFailed = createAction(
  '[Payments] Retry save payment to pms failed',
  props<{payment: Payment}>()
);

export const MarkPaymentsAsComplete = createAction(
  '[Payments] Mark payments as complete',
  props<{payments: Payment[]}>()
);

export const RefundPayment = createAction(
  '[Payments] Refund payment',
  props<{payment: Payment}>()
);

export const MarkPaymentsAsCompleteSuccess = createAction(
  '[Payments] Mark payments as complete success',
  props<{payments: Payment[]}>()
);

export const MarkPaymentsAsCompleteFailed = createAction(
  '[Payments] Mark payments as complete failed',
  props<{payments: Payment[]}>()
);

export const CapturePayments = createAction(
  '[Payments] Capture Payments',
  props<{payments: Payment[]}>()
);

export const PaymentsCaptureSuccess = createAction(
  '[Payments] Capture Payments - Success',
  props<{payments: Payment[]}>()
);

export const PaymentsCaptureFailed = createAction(
  '[Payments] Capture Payments - Failed',
  props<{payments: Payment[]}>()
);

export const CashUp = createAction(
  '[Payments] Cash up payments',
  props<{filters: CashUpFilters}>()
);

export const CashUpSuccess = createAction(
  '[Payments] Cash up payments Success',
  props<{payments: Payment[], includeCompleted: boolean}>()
);

export const CashUpFailed = createAction(
  '[Payments] Cash up payments Failed',
);

export const UpdatePaymentRequestLoading = createAction(
  '[Payments] Update Payment Request Loading',
  props<{isLoading: boolean}>()
);