import { Injectable } from '@angular/core';
import { TemplateMergeField } from '../../models/TemplateMergeField';
import { MergeFieldContent } from '../../enums/merge-field-content';
import { Practice } from '../../models/Practice';
import { Client } from '../../models/Client';
import { Patient } from '../../models/Patient';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderService {
  constructor() {}

  getMergeFieldReplacement(
    mergeField: TemplateMergeField,
    placeholders: { placeholderId: string; replacement: string }[],
    practice?: Practice | null,
    patient?: Patient | null,
    client?: Client | null
  ): string {
    switch (mergeField.content) {
      case MergeFieldContent.PracticeName:
        return (
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.ClientName:
        return (
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

        case MergeFieldContent.ClientPostcode:
        return (
          client?.postcode ||
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

        case MergeFieldContent.ClientTitle:
        return (
          client?.title ||
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

        case MergeFieldContent.ClientBalance:
        return (
          client?.postcode ||
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

        case MergeFieldContent.ClientFirstName:
        return (
          client?.firstName ||
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

        case MergeFieldContent.ClientLastName:
        return (
          client?.lastName ||
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

        case MergeFieldContent.PatientName:
        return (
          patient?.name ||
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.Custom:
        return (
          placeholders.find((p) => p.placeholderId === mergeField.id.toString())?.replacement ||
          mergeField.placeholder
        );

      default:
        return mergeField.placeholder;
    }
  }

  replacePlaceholders(
    text: string,
    mergeFields: TemplateMergeField[],
    placeholders: { placeholderId: string; replacement: string }[],
    practice?: Practice | null,
    patient?: Patient | null,
    client?: Client | null
  ): string {
    mergeFields.forEach((mergeField) => {
      text = text.replace(
        mergeField.placeholder,
        this.getMergeFieldReplacement(mergeField, placeholders, practice, patient, client)
      );
    });

    return text;
  }
}
