<div class="panel">
  <ng-container *ngIf="shareEnabled">
    <ng-container *ngIf="shared">
          <div class="share-complete">
              <i class="pi pi-check"></i>Shared
          </div>
      </ng-container>
      <ng-container *ngIf="!shared">
          <p-button class="btn-pms-share" [pTooltip]="shareLabel" tooltipPosition="left" styleClass="p-button-fourth p-button-sm" [disabled]="!!disabled" (click)="emitShareToPms()">
              <ng-container *ngIf="!shareLoading">
                <img alt="logo" class="icon" src="assets/images/icons/icon-share.svg" />
              </ng-container>
              <ng-container *ngIf="shareLoading">
                <p-progressSpinner [style]="{width: '17px', height: '17px'}" styleClass="dark-spinner" strokeWidth="6"></p-progressSpinner>
              </ng-container>
              <span class="p-ml-2">{{ (practice$ | async)?.pms }}</span>
          </p-button>
      </ng-container>
  </ng-container>
    <div class="cols">
        <div class="details-col">
            <div class="details-holder">
                <patient-details [disabled]="disabled" [patient]="patient" [changeDisabled]="changeDisabled" (patientChanged)="handlePatientChanged()"></patient-details>
            </div>
            <div class="actions-holder">
                <patient-actions [patient]="patient" [patientHistory]="patientHistory || []" [patientHistoryLoading]="patientHistoryLoading || false" (patientHistoryOpened)="handlePatientHistoryOpened($event)"></patient-actions>
            </div>
        </div>
    </div>
</div>
